<style lang="scss" scoped>
.ui-left {
  width: 360px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}

.button1{
  top:320px;

}

.button2{
  top:360px;

}

.button3{
  top:400px;

}
</style>


<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <img src="./ui-left.png" alt="" />
      <div class="nb button1" @click="show('normal')"></div>
      <div class="nb button2" @click="showtotat"></div>
      <div class="nb button3" @click="show('warning')"></div>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus"
export default {
  mounted(){
    bus.$emit( 'set-sprite-display', 'huanjingzhiliang', 'all', true)
  },
  methods: {
    show(name){
             bus.$emit("sceneRunning1",true)
      bus.$emit( 'set-sprite-display', 'huanjingzhiliang', name, true)
    },
    showtotat(){
      bus.$emit("totat")
    }
  },
};
</script>

<style  >

</style>